import React, { useState, Component, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faCheck, faPlus } from '@fortawesome/free-solid-svg-icons'

import moment from 'moment';

import { FavoritesModal } from '../floatingFavoritesButton/FavoritesModal';

import {
  selectAnalystAssignments,
  selectAnalystAssignmentCompletion,
  selectDqaWeekStart,
  selectDqaWeekEnd,
  setUserFavorites,
  selectUserFavorites
} from './userDashboardSlice';

import {
  selectCurrentUser,
} from '../menuBar/authSlice';

import styles from './UserDashboard.module.css';


interface UserDashboardProps {
  saveFavorite: any
  deleteFavorite: any
}
export function UserDashboard(props:UserDashboardProps) {
    const analystAssignments = useAppSelector(selectAnalystAssignments);
    const analystAssignmentCompletion = useAppSelector(selectAnalystAssignmentCompletion);
    const dqaWeekStart = useAppSelector(selectDqaWeekStart);
    const dqaWeekEnd = useAppSelector(selectDqaWeekEnd);
    const userFavorites = useAppSelector(selectUserFavorites);

    const [isShowingModal, setIsShowingModal] = useState<boolean>(false);

    const handleFavoriteButtonClick = () => {
        showModal()
    }

    const showModal = () => {
      setIsShowingModal(true)
    }

    const handleClose = () => {
      setIsShowingModal(false)
    }

    return (
        <div>
            <FavoritesModal saveFavorite={props.saveFavorite} isShowingModal={isShowingModal} onClose={handleClose} />
            <div className={styles.UserDashboardContainer}>
                <div className={styles.UserDashboardBackground}>
                   <div className={styles.UserDashboardTitleContainer}>
                        <p>Saved Favorites:</p>
                        <div className={styles.AddFavoriteButtonContainer}>
                            <button onClick={handleFavoriteButtonClick} className={styles.AddFavoriteButton}>Add More <FontAwesomeIcon icon={faPlus} /></button>
                        </div>
                    </div>
                    <div>
                        {
                           userFavorites.map((favObj:any, i:number) => {
                               return(
                                   <UserFavorite key={i+"_favorite"} deleteFavorite={props.deleteFavorite} datastreams={favObj.datastreams} sdate={favObj.sdate} edate={favObj.edate} user={favObj.query_user} isDQAAssignment={false} isComplete={null}/>
                               )
                           })
                        }
                    </div>
                    { analystAssignments !== null && analystAssignments.length !== 0 ? 
                        <div>
                            <div className={styles.UserDashboardTitleContainer}>
                                <p>DQA Assignments:</p>
                            </div>
                            {analystAssignments.map((dsArr:string[], i:number) => {
                               let isComplete = analystAssignmentCompletion![i]
                               return(
                                   <UserFavorite key={i+"_favorite"} deleteFavorite={props.deleteFavorite} datastreams={dsArr} sdate={dqaWeekStart} edate={dqaWeekEnd} user={"dqmgr"} isDQAAssignment={true} isComplete={isComplete}/>
                               )
                           })}
                        </div>
                    :
                        null
                    }

                </div>
            </div>
        </div>
    )
} 


interface UserFavoriteProps {
  datastreams: string[]
  sdate: string
  edate: string
  user: string
  isDQAAssignment: boolean
  isComplete: null | boolean
  deleteFavorite: any
}
const UserFavorite = (props:UserFavoriteProps) => {
    const dispatch = useAppDispatch();
    const currentUser = useAppSelector(selectCurrentUser);
    const userFavorites = useAppSelector(selectUserFavorites);

    const [linkStr, setLinkStr] = useState<string>("")

    const genLink = () => {
        let linkStr = window.location.origin + "/#/?u=dqmgr&"

        for(let i=0; i<props.datastreams.length; i++) {
            linkStr += 'd=' + props.datastreams[i] + '&'
            linkStr += 's=' + props.sdate + '&'
            linkStr += 'e=' + props.edate + '&'
        }

        setLinkStr(linkStr)
    }

    useEffect(() => {
        genLink()
    }, [props.sdate, props.edate, props.datastreams])


    const handleDelete = () => {
        let favObj = {
            "datastreams": props.datastreams,
            "sdate": props.sdate,
            "edate": props.edate,
            "query_user": props.user,
        }

        var result = window.confirm("Are you sure you want to delete this favorite?");
        if (result) {
            props.deleteFavorite(currentUser!.armID, favObj, (res:any) => {
                if(!('data' in res) || 'error' in res.data.result){
                  alert("Error deleting favorite.")
                } else {
                    let dummyFavData = [...userFavorites];

                    const index = userFavorites.findIndex( (fav) => (fav.datastreams === favObj['datastreams'] && fav.sdate === favObj['sdate'] && fav.edate === favObj['edate'] && fav.query_user === favObj["query_user"] ));
                    dummyFavData.splice(index, 1)
                    dispatch(setUserFavorites(dummyFavData))
                }
            })
        }


        
    }

    let dateLabel = ""

    if (props.sdate.includes("$latest")) {
        let sdate_parts = props.sdate.split('-')
        let sdateStr = moment().subtract(parseInt(sdate_parts[1]),'days').format('YYYYMMDD')
        let edateStr = moment().format('YYYYMMDD')

        dateLabel = "Last " + sdate_parts[1] + " days (" + sdateStr + " - " + edateStr + ")" 

    } else {
        dateLabel = props.sdate + " - " + props.edate
    }

    return (

            <div className={styles.UserFavoriteContainer}>
                <div className={styles.UserFavoriteLeftContainer}>
                    <a target={"_blank"} href={linkStr}>
                    <div className={styles.UserFavoriteDatastreamsContainer}>
                        <p className={styles.UserFavoriteLabel}>Datastream(s):</p> 
                        
                        {props.datastreams.map((val, index) => {
                            return(
                                <p key={index.toString()+val} className={styles.UserFavoriteValue}>
                                    {index == props.datastreams.length-1 ? val : val + ", "}
                                </p>
                            )
                        })}

                    </div>

                    <div className={styles.UserFavoriteDateContainer}>
                        <p className={styles.UserFavoriteLabel}>Date Range:</p> 
                        <p className={styles.UserFavoriteDate}>
                            {dateLabel}
                        </p>
                    </div>
                    { props.user !== "dqmgr" ? 
                         <div className={styles.UserFavoriteUserContainer}>
                            <p className={styles.UserFavoriteLabel}>(User:</p> 
                            <p className={styles.UserFavoriteUser}>
                               {props.user})
                            </p>
                        </div>
                    :
                        null
                    }
                    </a>
                </div>
                {!props.isDQAAssignment ? 
                    <div onClick={handleDelete} className={styles.UserFavoriteDeleteContainer}>
                        <FontAwesomeIcon className={styles.UserFavoriteDelete} icon={faTimes} />
                    </div>
                : props.isComplete ? 
                    <div className={styles.AnalystAssignmentCompletionContainer}>
                        <FontAwesomeIcon className={styles.AssignmentCompleteIcon} icon={faCheck} />
                    </div>
                :
                    null
                }
                
            </div>

    )
}

